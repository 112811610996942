body {
  font-family: "Bradley";
  overflow-x: hidden;
  background: url("./_files/bg.jpg") no-repeat center center fixed;
  background-size: cover;
}

.Header {
  margin-top: 0px;
  height: 4vw;
  width: 100%;
  z-index: 999;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  grid-template-columns: repeat(2, 30%);
}

.LeftHeader {
  width: 20vw;
  margin-top: 0.7em;
  margin-left: 0;
  font-size: 1.7vw;
  font-weight: 600;
  z-index: 9999;
  color: #ffffff;
  font-family: "Agency FB";
  src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
}

.RightHeader {
  width: 60vw;
  margin-top: 1.2em;
  margin-left: 2.5vw;
  font-size: 1.2vw;
  font-weight: 300;
  color: #ffffff;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  z-index: 9999;
}

.TopLink {
  font-size: 1.3vw;
  font-weight: 500;
  color: #ffffff;
  font-family: "Arial Narrow";
  text-decoration: none;
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

ul li {
  margin-left: 3vw;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.FirstContainer {
  display: grid;
  width: 80vw;
  height: 40vh;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(2, 55%);
}

.LeftC {
  width: 45vw;
}

.RightC {
  width: 35vw;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-weight: bold;
  font-family: "Agency FB";
  src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
}

.LeftParagraph {
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  font-family: "Agency FB";
  src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
}

.L1 {
  font-size: 40pt;
}

.L2 {
  font-size: 20pt;
}

.L2Arial {
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  font-size: 17pt;
}

.L3 {
  font-size: 30pt;
}

.HeaderBtn {
  width: auto;
  height: 2.2em !important;
  margin-left: 1.5em;
  margin-top: 3em;
  height: 2.5vw;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.856);
  border-radius: 10px;
  font-size: 1.3vw;
  font-weight: bold;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  z-index: 10001;
  cursor: pointer;
  border: 0;
}

.Bottom {
  margin-top: 7em;
  width: 60vw;
  height: 20vh;
  margin-left: auto;
  margin-right: auto;
}

.LeftBC {
  width: 35vw;
}

.RightBC {
  text-align: left;
  width: 45vw;
}

.LeftParagraphB {
  color: #ffffff;
  text-align: justify;
  font-size: 15pt;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
}

.TopLable {
  cursor: pointer;
}

.RightBCPic {
  width: 35vw;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.btnlink {
  font-size: 1.3vw;
  font-weight: bold;
  color: #000000;
  font-family: "Arial Narrow";
  text-decoration: none;
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
}

.Fifth {
  width: 65vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 25%);
}

.FifthSlice {
  font-family: "Agency FB";
  src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
  color: #ffffff;
  font-size: 1.4em;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  width: 13vw;
}

.orgParagraph {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
}

.SecondBottom {
  width: 70vw;
  height: 30vh;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  font-size: 1.5vw;
  font-weight: 600;
}

.ThirdBottom {
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
}

.orgParagraph {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  font-family: "Arial Narrow";
  src: url(../_sections/_files/ARIALN.TTF) format("truetype");
}

.OC {
  width: 80vw;
  height: auto;
}

.alink {
  color: #ffffff;
  text-decoration: none;
}

.ContactDetails {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  text-align: left;
  font-size: 1.4em;
}

.Venue {
  width: 50vw;
  height: auto;
}

.MainText2 {
  z-index: 50;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.7vw;
  font-weight: 600;
}

.Paragraph {
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  line-height: 2.5vw;
}

.ParagraphEdu {
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  line-height: 2.5vw;
  font-size: 1.2vw;
  font-family: "Courier New", Courier, monospace;
}

@media only screen and (max-width: 500px) {
  .Header {
    margin-top: 0px;
    height: 12vw;
    width: 100%;
    z-index: 999;
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    grid-template-columns: repeat(2, 30%);
  }
  .LeftHeader {
    width: 15vw;
    margin-top: 0em;
    margin-left: 0;
    font-size: 2em;
    font-weight: 600;
  }
  .RightHeader {
    width: 65vw;
    margin-left: 0;
  }
  .TopLink {
    font-size: 3em;
    font-weight: 100;
  }
  ul li {
    margin-left: 1vw;
    display: block;
    text-decoration: none;
  }
  .FirstContainer {
    display: inherit;
    width: 80vw;
    height: auto;
  }
  .LeftC {
    margin-top: 5em;
    width: 80vw;
  }
  .RightC {
    width: 80vw;
    height: auto;
  }
  .LeftParagraph {
    font-size: 2em;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
    font-family: "Agency FB";
    src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
  }
  .PCBR {
    display: none;
  }
  .L1 {
    font-size: 1em;
  }
  .L2 {
    font-size: 0.9em;
  }
  .Bottom {
    margin-top: 0em;
    width: 80vw !important;
    text-align: left;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .HeaderBtn {
    width: auto;
    height: 2em !important;
    margin-top: 10px !important;
    margin-left: 5px;
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    border: 0;
  }
  .LeftParagraphB {
    color: #ffffff;
    text-align: justify;
    font-size: 1em;
    font-family: "Arial Narrow";
    src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  }
  .LeftBC {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .RightBC {
    text-align: left;
    width: 45vw;
  }
  .TopLable {
    cursor: pointer;
  }
  .RightBCPic {
    width: 80vw;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .ThirdBottom {
    width: 80vw;
    height: auto !important;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .orgParagraph {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    font-family: "Arial Narrow";
    src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  }
  .OC {
    width: 80vw !important;
    height: auto;
  }
  .Fifth {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
  .FifthSlice {
    font-family: "Agency FB";
    src: url(../_sections/_files/AGENCYB.TTF) format("truetype");
    color: #ffffff;
    font-size: 1.2em;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    padding: 0.5em;
  }
  .Venue {
    width: 80vw;
    height: auto;
  }
  .btnlink {
    font-size: 1em;
    font-weight: bold;
    color: #000000;
    font-family: "Arial Narrow";
    text-decoration: none;
    src: url(../_sections/_files/ARIALN.TTF) format("truetype");
  }
}/*# sourceMappingURL=Style.css.map */