.Content {
  height: 100vh;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background: url("./_files/bg.jpg") no-repeat center center fixed;
  background-size: cover;
}

.Content2 {
  height: 55vh;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.Content3 {
  height: auto;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.Content4 {
  height: auto;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgb(255, 255, 255);
}

.Content5 {
  height: auto;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.Content6 {
  height: auto;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.Content7 {
  height: 60vh;
  width: 100%;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 500px) {
  .Content {
    display: inherit;
    height: auto;
    background-size: contain;
  }
  .Content2 {
    display: inherit;
    height: auto;
  }
  .Content3 {
    display: inherit;
    height: auto;
  }
  .Content4 {
    display: inherit;
    height: auto;
  }
  .Content5 {
    display: inherit;
    height: auto;
  }
  .Content6 {
    display: inherit;
    height: auto;
  }
  .Content7 {
    display: inherit;
    height: auto;
  }
}/*# sourceMappingURL=FullpageDiv.css.map */